export const LOCALES = {
  CAD: "en-CA",
  HKD: "zh-HK",
  ISK: "is",
  PHP: "en-PH",
  DKK: "da",
  HUF: "hu",
  CZK: "cs",
  GBP: "en-GB",
  RON: "ro",
  SEK: "sv-SE",
  IDR: "id",
  INR: "en-IN",
  BRL: "pt-BR",
  RUB: "ru",
  HRK: "hr",
  JPY: "ja",
  THB: "th",
  CHF: "fr-CH",
  EUR: "de-DE",
  MYR: "ms-MY",
  BGN: "bg",
  TRY: "tr",
  CNY: "zh-CN",
  NOK: "no-NO",
  NZD: "en-NZ",
  ZAR: "en-ZA",
  USD: "en-US",
  MXN: "es-MX",
  SGD: "zh-SG",
  AUD: "en-AU",
  ILS: "he-IL",
  KRW: "ko-KR",
  PLN: "pl",
};
